<template>
  <div class="dialog-container" id="orders">
    <transition name="el-fade-in" mode="out-in">
      <el-row style="display: flex; align-items: center; justify-content: center; width: 100%" v-if="loading == 'updateScreenLoading' && orderForm == null" key="screen-loading">
        <lottie-player autoplay loop mode="normal" src="/lottie/packaging-source-loading-lottie.json" style="width: 320px"></lottie-player>
      </el-row>
      <el-row v-else key="content-loading">
        <div class="dialog-title">
          <div class="head-title">Create Order Registration<span class="yellow-dot">.</span></div>
          <el-button class="btn close" type="danger" @click="close()"> <i class="icon-xmark-solid"></i></el-button>
        </div>
        <div class="form">
          <el-row :gutter="24">
            <el-col :sm="12" style="margin-top: 0">
              <div class="input-container">
                <div class="title">Customer Name<span class="yellow-dot">.</span></div>
                <el-select v-model="orderForm.CustomerId" placeholder="Select" clearable @change="clearSubOrders" filterable>
                  <el-option v-for="item in getAllCustomers" :key="item.value" :label="item.CompanyName" :value="item.ID"> </el-option>
                </el-select>
                <span class="error-validation">{{ $errorMessage("CustomerId", validation) }}</span>
              </div>
            </el-col>
          </el-row>
          <div class="line" v-if="stockList && orderForm.CustomerId"></div>
          <div class="title" v-if="stockList && orderForm.CustomerId">Product Stock<span class="yellow-dot">.</span></div>

          <div class="table-container">
            <div class="view-info" v-if="stockList && orderForm.CustomerId">
              <p>You are viewing page {{ StockFilter.Page }} out of a total of {{ stocktotalCount.PageCount }} pages. (Total Records: {{ stocktotalCount.TotalCount }})</p>
              <el-button class="refresh-btn"> <i class="icon-arrows-rotate-solid"></i></el-button>
            </div>
            <el-table style="width: 100%" :data="stockList" v-if="stockList && orderForm.CustomerId">
              <el-table-column width="80">
                <template slot-scope="scope">
                  <el-checkbox @change="checkedList(scope.row.ID)" :checked="checkList.some((x) => x == scope.row.ID)" :key="scope.row.ID + '-check-list-item'"></el-checkbox>
                </template>
              </el-table-column>
              <el-table-column prop="Product.SKU" label="SKU" width="180"> </el-table-column>
              <el-table-column prop="Product.Title" label="Product Name"></el-table-column>
              <el-table-column prop="Warehouse.Title" label="Warehouse Name"></el-table-column>
              <el-table-column prop="BoxQuantity" label="Box Quantity"></el-table-column>
              <el-table-column prop="Box.PiecesInBox" label="Pieces In Box"></el-table-column>
            </el-table>
            <div class="table-bottom stocks-pagination" v-if="stockList && orderForm.CustomerId">
              <div class="info">
                <span>Show</span>
                <el-select v-model="StockFilter.PageSize">
                  <el-option label="12" :value="12"></el-option>
                  <el-option label="24" :value="24"></el-option>
                  <el-option label="36" :value="36"></el-option>
                  <el-option label="100" :value="100"></el-option>
                  <el-option label="200" :value="200"></el-option>
                </el-select>
                <span>entries</span>
              </div>
              <el-pagination layout="prev, pager, next" :current-page.sync="StockFilter.Page" :page-size="StockFilter.PageSize" :total="stocktotalCount.TotalCount"> </el-pagination>
            </div>
          </div>
          <div class="dialog-footer" v-if="stockList && orderForm.CustomerId">
            <el-button class="btn" type="primary" @click="addDeliveries"> <i class="icon-plus-solid"></i> Add Selected Products</el-button>
          </div>
          <span class="error-validation" v-if="orderForm.CustomerId">{{ $errorMessage("SubOrders", validation) }}</span>

          <div class="line"></div>
          <el-collapse accordion>
            <el-collapse-item v-for="(item, index) in orderForm.SubOrders" :key="index">
              <template slot="title">
                <div class="row-title">
                  <div class="title">Order {{ index + 1 }} <span class="yellow-dot">.</span></div>
                  <div class="icon">
                    <div class="icon-row-trash" @click="deleteNewOrder(index)"></div>
                  </div>
                </div>
              </template>
              <div class="form" style="margin: 0">
                <div class="select-products">
                  <div class="title">Selected Products<span class="yellow-dot">.</span></div>
                  <el-row :gutter="24" v-for="(deliveryItem, deliveryIndex) in item.Deliveries" :key="deliveryItem.ID">
                    <el-col class="input-container" :sm="12">
                      <div class="select-product">
                        <el-checkbox checked disabled class="text"></el-checkbox>
                        <div class="text">{{ deliveryItem.ProductName }}</div>
                      </div>
                    </el-col>
                    <el-col class="input-container" :sm="12">
                      <div class="text">Box Quantity</div>
                      <el-input v-model="deliveryItem.BoxQuantity" placeholder="..."></el-input>
                      <span class="error-validation">{{ $errorMessage("SubOrders[" + index + "].Deliveries[" + deliveryIndex + "].BoxQuantity", validation) }}</span>
                    </el-col>
                  </el-row>
                </div>
                <div class="line"></div>
                <div class="title">Delivery Address<span class="yellow-dot">.</span></div>
                <el-row :gutter="24">
                  <el-col class="input-container" :sm="24">
                    <el-input v-model="item.DeliveryAddress" class="textarea" type="textarea" rows="5" placeholder="Add Address..."></el-input>
                  </el-col>
                </el-row>
                <div class="line"></div>
                <el-row :gutter="24" style="margin-top: 10px">
                  <el-col class="input-container" :sm="12">
                    <div class="text">Order Date</div>
                    <el-date-picker format="dd-MM-yyyy" v-model="item.OrderDate" prefix-icon="icon-calendarplus" type="date" placeholder="Pick a day" :default-value="new Date()"> </el-date-picker>
                    <span class="error-validation">{{ $errorMessage("SubOrders[" + index + "].OrderDate", validation) }}</span>
                  </el-col>
                  <span class="error-validation">{{ $errorMessage("OrderDate", validation) }}</span>
                  <el-col class="input-container" :sm="12">
                    <div class="text">Estimated Delivery Date</div>
                    <el-date-picker format="dd-MM-yyyy" v-model="item.EstimatedDeliveryDate" prefix-icon="icon-calendarplus" type="date" placeholder="Pick a day"> </el-date-picker>
                    <span class="error-validation">{{ $errorMessage("SubOrders[" + index + "].EstimatedDeliveryDate", validation) }}</span>
                  </el-col>
                </el-row>

                <div class="line"></div>
                <el-row :gutter="24">
                  <el-col :sm="24" class="right-card">
                    <div class="inline-form">
                      <el-row style="display: flex; align-items: center">
                        <el-col :span="12">
                          <div class="text">
                            <i class="icon-agent"></i>
                            <div>Agent</div>
                          </div>
                        </el-col>
                        <el-col :span="12">
                          <el-select v-model="item.AgentId" placeholder="Select" style="width: 100%" clearable="">
                            <el-option v-for="(item, index) in agentList.filter((x) => x.IsDeactivated != true)" :key="index" :label="item.Title" :value="item.ID"> </el-option>
                          </el-select>
                          <span class="error-validation">{{ $errorMessage("SubOrders[" + index + "].AgentId", validation) }}</span>
                        </el-col>
                      </el-row>
                    </div>
                  </el-col>
                </el-row>
                <div class="line"></div>
                <div class="title">Comments<span class="yellow-dot">.</span></div>
                <el-row :gutter="24">
                  <el-col class="input-container" :sm="24">
                    <el-input v-model="item.OrderNote" class="textarea" type="textarea" rows="5" placeholder="Add Comment..."></el-input>
                  </el-col>
                </el-row>
                <div class="line"></div>
                <el-row :gutter="24">
                  <el-col class="input-container" :sm="12">
                    <div class="title">Order Fee</div>
                    <div class="transfer-fee" style="display: flex; align-items: center; gap: 10px">
                      <span style="font-size: 16px">£</span>
                      <el-input v-model="item.SubOrderFee" @input="fixPrices" placeholder="..."></el-input>
                      <span class="error-validation">{{ $errorMessage("SubOrders[" + index + "].SubOrderFee", validation) }}</span>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </el-collapse-item>
          </el-collapse>
          <div class="dialog-footer footerbutton" style="padding: 0">
            <el-button class="btn" type="danger" @click="close()"> <i class="icon-xmark-solid"></i>Cancel</el-button>
            <el-button class="btn" type="info" @click="orderAddUpdate" :loading="loading == 'saveLoading'" :icon="'el-icon-plus'">Add Order</el-button>
          </div>
        </div>
      </el-row>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      timeout: null,
      checkList: [],
      orderForm: null,
      stockList: [],
    };
  },

  methods: {
    fixedInput(input) {
      var s = input.toString();
      var reg = /[0-9]+([,.][0-9]{0,2})?/i;
      return reg.exec(s)[0].replaceAll(",", ".");
    },
    fixPrices() {
      for (let SubOrder of this.orderForm.SubOrders) {
        SubOrder.SubOrderFee = this.fixedInput(SubOrder.SubOrderFee);
      }
    },
    beforeSubmit() {
      for (let SubOrder of this.orderForm.SubOrders) {
        SubOrder.SubOrderFee = parseFloat(SubOrder.SubOrderFee);
      }
    },

    async arrivedOrder() {
      var payload = {
        OrderId: this.orderForm.ID,
      };

      this.$confirm("Are you sure you want to change the status to in arrived?", "Warning", {
        confirmButtonText: "Yes",
        confirmButtonClass: "danger-btn-confirm",
        cancelButtonText: "No",
        type: "warning",
      })
        .then(async () => {
          var res = await this.$client.post("/Order/Arrived", payload);
          if (res.HasError) {
            this.$message.error(res.data.Message);
          } else {
            this.$message.success(res.data.Message);
            this.$store.dispatch("getOrderList");
          }
          this.close();
        })
        .catch(() => {});
    },

    clearSubOrders() {
      this.orderForm.SubOrders = [];
      this.StockFilter.CustomerId = this.orderForm.CustomerId
    },
    deleteNewOrder(index) {
      this.orderForm.SubOrders.splice(index, 1);
    },
    addDeliveries() {
      var arr = this.stockList.filter(({ ID }) => this.checkList.includes(ID));
      arr.map((x) => {
        var payload = {
          WarehouseItemId: x.ID,
          ProductId: x.Product.ID,
          ProductName: x.Product.Title,
          BoxQuantity: x.BoxQuantity,
          Box: x.Box,
          SKU: x.Product.SKU,
        };

        if (this.orderForm.SubOrders.some((y) => y.WarehouseId == x.WarehouseId)) {
          if (this.orderForm.SubOrders.find((y) => y.WarehouseId == x.WarehouseId).Deliveries.some((k) => k.ProductId == x.Product.ID)) {
            this.$message.warning("This product has already been added.");
          } else {
            this.orderForm.SubOrders.find((y) => y.WarehouseId == x.WarehouseId).Deliveries.push(payload);
          }
        } else {
          this.orderForm.SubOrders.push({ WarehouseId: x.WarehouseId, OrderNo: null, AgentId: null, CustomerId: null, EstimatedDeliveryDate: null, OrderDate: new Date(), OrderFee: null, OrderNote: null, Deliveries: [] });
          this.orderForm.SubOrders.find((y) => y.WarehouseId == x.WarehouseId).Deliveries.push(payload);
        }
      });
    },

    checkedList(id) {
      if (this.checkList.some((x) => x == id)) {
        var cIndex = this.checkList.findIndex((x) => x == id);
        if (cIndex != -1) {
          this.checkList.splice(cIndex, 1);
        }
      } else {
        this.checkList.push(id);
      }
    },
    develop() {
      this.$message.info("Bu özellik geliştirme aşamasındadır...");
      return;
    },
    close(id) {
      this.$emit("close", id);
    },
    async getProductsStock() {
      var payload = { WarehouseId: null, CustomerId: this.orderForm.CustomerId, Page: 1, PageSize: 9999 };
      var res = await this.$store.dispatch("getProductsStock", payload);
      if (!res.data.HasError) {
        this.stockList = res.data.Data.Items;
      }
    },
    async orderAddUpdate() {
      this.beforeSubmit();
      this.$store.commit("setOrderValidation", []);
      if (this.validation.length > 0) {
        return;
      }
      this.loading = "saveLoading";
      if (this.timeout != null) clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        var res = await this.$store.dispatch("orderAddUpdate", this.orderForm);
        if (!res.HasError) {
          this.orderForm = {
            CustomerId: null,
            SubOrders: [],
          };
          this.close();
        }
        this.loading = false;
      }, 500);
    },
  },
  async created() {
    this.loading = "updateScreenLoading";
    if (this.timeout != null) clearTimeout(this.timeout);
    this.timeout = setTimeout(async () => {
      await this.$store.dispatch("getCustomersList", { Page: 1, PageSize: 999999, SearchTerm: null }), await this.$store.dispatch("getOrderList"),await this.$store.dispatch("getProductsStock", this.StockFilter);
      this.orderForm = {
        CustomerId: null,
        SubOrders: [],
      };
      this.loading = false;
    }, 500);
  },
  watch: {
    "orderForm.CustomerId": async function () {
      this.orderForm.SubOrders.Deliveries = [];
      this.checkList = [];
      this.StockFilter.CustomerId = this.orderForm.CustomerId;
      var res =  await this.$store.dispatch("getProductsStock",this.StockFilter);
       if(!res.data.HasError){
        this.stockList = res.data.Data.Items;
       }
    },
    StockFilter: {
      deep: true,
      async handler() {
       var res =  await this.$store.dispatch("getProductsStock",this.StockFilter);
       if(!res.data.HasError){
        this.stockList = res.data.Data.Items;
       }
      },
    },
  },
  computed: {
    agentList() {
      return this.$store.getters.getAgentList;
    },
    getAllCustomers() {
      return this.$store.getters.getCustomersList;
    },
    getEnums() {
      return this.$store.getters.getEnums;
    },
    StockFilter() {
      return this.$store.getters.getstockFilter;
    },
    stocktotalCount() {
      return this.$store.getters.getstockTotalCount;
    },
    validation() {
      return this.$store.getters.getOrderValidation;
    },
  },
};
</script>

<style lang="less"></style>
