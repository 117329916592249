<template>
  <div class="dialog-container" id="order-details">
    <transition name="el-fade-in" mode="out-in">
      <el-row style="display: flex; align-items: center; justify-content: center; width: 100%" v-if="loading == 'updateScreenLoading' && subOrder == null" key="screen-loading">
        <lottie-player autoplay loop mode="normal" src="/lottie/packaging-source-loading-lottie.json" style="width: 320px"></lottie-player>
      </el-row>
      <el-row v-else key="content-loading">
        <div class="dialog-title">
          <div class="head-title">Order Details<span class="yellow-dot">.</span> #{{ order.OrderNo }}</div>
          <el-button class="btn close" type="danger" @click="close()"> <i class="icon-xmark-solid"></i></el-button>
        </div>
        <div class="form" v-if="order">
          <el-row :gutter="24">
            <el-col :sm="12" style="margin-top: 0">
              <div class="input-container" v-if="order.Customer">
                <div class="title">Customer Name<span class="yellow-dot">.</span></div>
                <el-select placeholder="Select" disabled v-model="order.Customer.CompanyName"> </el-select>
              </div>
            </el-col>
            <el-col :sm="12" style="margin-top: 0" v-if="subOrder.Warehouse">
              <div class="input-container">
                <div class="title">Warehouse Name<span class="yellow-dot">.</span></div>
                <el-input placeholder="..." disabled v-model="subOrder.Warehouse.Title"> </el-input>
              </div>
            </el-col>
          </el-row>
          <div class="line"></div>
          <div class="title">Delivery Address<span class="yellow-dot">.</span></div>
          <el-row :gutter="24">
            <el-col class="input-container" :sm="24">
              <el-input v-model="subOrder.DeliveryAddress" class="textarea" type="textarea" rows="5" placeholder="Add Address..."></el-input>
            </el-col>
          </el-row>
          <div class="line"></div>
          <div class="title">Product Informations<span class="yellow-dot">.</span></div>
          <div class="mini-form" v-for="(item, index) in subOrder.Deliveries" :key="index">
            <el-row :gutter="24">
              <el-col :sm="12" class="input-container">
                <div class="text">SKU</div>
                <div>
                  <el-input placeholder="..." disabled v-model="item.SKU"></el-input>
                </div>
              </el-col>
              <el-col :sm="12" class="input-container">
                <div class="text">Product Name</div>
                <el-input placeholder="..." disabled v-model="item.ProductName"></el-input>
              </el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col :sm="12" class="input-container">
                <div class="text">Box Type</div>
                <el-row :gutter="24" class="input-container-row">
                  <el-col :sm="8">
                    <el-input placeholder="..." disabled v-model="item.Box.BoxDimensions.X"></el-input>
                  </el-col>
                  <el-col :sm="8">
                    <el-input placeholder="..." disabled v-model="item.Box.BoxDimensions.Y"></el-input>
                  </el-col>
                  <el-col :sm="8">
                    <el-input placeholder="..." disabled v-model="item.Box.BoxDimensions.Z"></el-input>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :sm="12" class="input-container">
                <div class="text">Box Quantity</div>
                <el-input placeholder="..." disabled v-model="item.BoxQuantity"></el-input>
              </el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col :sm="12" class="input-container">
                <div class="text">Pieces In Box</div>
                <el-input placeholder="..." disabled v-model="item.Box.PiecesInBox"></el-input>
              </el-col>
              <el-col :sm="12" class="input-container">
                <div class="text">Total Weight</div>
                <el-input placeholder="..." disabled :value="item.Box.Weight * item.BoxQuantity"></el-input>
              </el-col>
            </el-row>
          </div>
          <div class="line"></div>
          <el-row :gutter="24" style="margin-top: 10px">
            <el-col class="input-container" :sm="12">
              <div class="text">Order Date</div>
              <el-date-picker format="dd-MM-yyyy" prefix-icon="icon-calendarplus" type="date" placeholder="Pick a day" disabled v-model="order.CreatedDateTime"> </el-date-picker>
            </el-col>
            <el-col class="input-container" :sm="12">
              <div class="text">Estimated Delivery Date</div>
              <el-date-picker format="dd-MM-yyyy" prefix-icon="icon-calendarplus" type="date" placeholder="Pick a day" disabled v-model="subOrder.EstimatedDeliveryDate"> </el-date-picker>
            </el-col>
          </el-row>
          <div class="line"></div>
          <el-row :gutter="24" v-if="subOrder.Agent" style="display: flex; align-items: center">
            <el-col :span="12">
              <div class="text" style="display: flex; align-items: center; gap: 8px">
                <i class="icon-agent"></i>
                <div>Agent</div>
              </div>
            </el-col>
            <el-col :span="12">
              <el-select placeholder="Select" style="width: 100%" disabled v-model="subOrder.Agent.Title"> </el-select>
            </el-col>
          </el-row>
          <!-- <el-row :gutter="24">
            <el-col :span="12">
              <div class="text" style="display: flex; align-items: center; gap: 8px">
                <i class="icon-truck"></i>
                <div>Vehicle Details</div>
              </div>
            </el-col>
            <el-col :span="12">
              <el-input class="vehicle-input" placeholder="..." disabled v-model="subOrder.Vehicle"> </el-input>
            </el-col>
          </el-row> -->
          <div class="line"></div>
          <div class="title">Comments<span class="yellow-dot">.</span></div>
          <el-row :gutter="24">
            <el-col class="input-container" :sm="24">
              <el-input class="textarea" type="textarea" rows="5" placeholder="Add Comment..." disabled v-model="subOrder.OrderNote"></el-input>
            </el-col>
          </el-row>
          <div class="line"></div>
          <el-row :gutter="24">
            <el-col class="input-container" :sm="12">
              <div class="title">Order Fee</div>
              <div class="transfer-fee" style="display: flex; align-items: center; gap: 10px">
                <span style="font-size: 16px">£</span>
                <el-input placeholder="..." disabled v-model="subOrder.SubOrderFee"></el-input>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="dialog-footer footerbutton">
          <el-button class="btn" type="danger" @click="close()"> <i class="icon-xmark-solid"></i> Cancel</el-button>
          <el-button class="btn" type="info" @click="exportDeliveryNote"><i class="icon-filter-export"></i>Export Delivery Note</el-button>
          <el-button class="btn" type="primary" v-if="[1, 2].includes(subOrder.Status)" @click="arrivedSubOrder()" :loading="loading == 'saveLoading'"><i class="icon-check-double-solid" v-if="loading == false"></i>Delivered</el-button>
        </div>
      </el-row>
    </transition>
  </div>
</template>

<script>
export default {
  props: ["id"],
  name: "orderDetails",
  data() {
    return {
      order: null,
      subOrder: null,
      loading: false,
      timeout: null,
    };
  },
  async created() {
    this.loading = "updateScreenLoading";
    this.timeout = setTimeout(async () => {
      await this.getOrder();

      console.log("girdim", this.order, this.subOrder);
      this.loading = false;
      this.subOrder.SubOrderFee = this.subOrder.SubOrderFee.toLocaleString("en", { useGrouping: false, minimumFractionDigits: 2, maximumFractionDigits: 5 });
    }, 500);
  },
  methods: {
    async getOrder() {
      var res = await this.$store.dispatch("getOrder", this.id);
      if (res.HasError) {
        this.close();
      } else {
        this.order = res.Data;
        this.subOrder = this.order.SubOrder;
      }
    },
    async checkedId() {
      if (this.id) {
        await this.getOrder();
      }
      // else {
      //   this.form = JSON.parse(JSON.stringify(this.form));
      // }
    },

    close() {
      this.$emit("close");
    },
    exportDeliveryNote() {
      var id = this.id;
      var token = this.$cookies.get("token")
      // window.open(`http://188.166.145.241/delivery-note.html?id=${id}&subOrderNo=${this.subOrderNo}&token=${token}`);
      window.open(`https://pdf.imageus.dev/?url=https://portal.packagingsource.co.uk/delivery-note.html?id=${id}=idtoken-seperator=${token}`);
    },
    arrivedSubOrder() {
      this.loading = "saveLoading";
      if (this.timeout != null) clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        var payload = {
          orderId: this.id,
          status: 0,
        };

        this.$confirm("Are you sure to arrived to this order?", "Warning", {
          confirmButtonText: "Confirm",
          confirmButtonClass: "danger-btn-confirm",
          cancelButtonText: "Back",
          type: "warning",
        })
          .then(async () => {
            var res = await this.$client.post("/Order/Arrived", payload);
            if (res.data.HasError) {
              this.$message.error(res.data.Message);
            } else {
              this.$message.success(res.data.Message);
              await this.$store.dispatch("getOrderList");
              this.close();
            }
          })
          .catch(() => {});
        this.loading = false;
      }, 500);
    },
  },
  watch: {
    id() {
      this.checkedId();
    },
  },
};
</script>
